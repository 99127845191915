import React from 'react';
import './all.css';

function All() {
  return (
    <>
      <section className="blog pt-70 pb-100">
        <div className="container">
          <div className="row">
            {/* الموالح */}
            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="blog-card blog-card-2">
                <div className="blog-img-area">
                  <a href="/crop/02">
                    <img
                      className="allcropsImg"
                      src="/assets/images/media/crops/02/055.jpg"
                      alt="img"
                    />
                  </a>
                </div>
                <div className="blog-text-area text-center">
                  <div className="blog-date">
                    <ul>
                      <li>
                        <a href="/crop/02" className="h3">
                          الموالح
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* العنب */}
            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="blog-card blog-card-2">
                <div className="blog-img-area">
                  <a href="/crop/04">
                    <img
                      className="allcropsImg"
                      src="/assets/images/media/crops/04/04.jpg"
                      alt="img"
                    />
                  </a>
                </div>
                <div className="blog-text-area text-center">
                  <div className="blog-date">
                    <ul>
                      <li>
                        <a href="/crop/04" className="h3">
                          العنب
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* المانجو */}
            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="blog-card blog-card-2">
                <div className="blog-img-area">
                  <a href="/crop/07">
                    <img
                      className="allcropsImg"
                      src="/assets/images/media/crops/07/07.jpg"
                      alt="img"
                    />
                  </a>
                </div>
                <div className="blog-text-area text-center">
                  <div className="blog-date">
                    <ul>
                      <li>
                        <a href="/crop/07" className="h3">
                          المانجو
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* الرمان*/}
            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="blog-card blog-card-2">
                <div className="blog-img-area">
                  <a href="/crop/01">
                    <img
                      className="allcropsImg"
                      src="/assets/images/media/crops/01/01.jpg"
                      alt="img"
                    />
                  </a>
                </div>
                <div className="blog-text-area text-center">
                  <div className="blog-date">
                    <ul>
                      <li>
                        <a href="/crop/01" className="h3">
                          الرمان
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* الفراولة*/}
            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="blog-card blog-card-2">
                <div className="blog-img-area">
                  <a href="/crop/03">
                    <img
                      className="allcropsImg"
                      src="/assets/images/media/crops/03/03.jpg"
                      alt="img"
                    />
                  </a>
                </div>
                <div className="blog-text-area text-center">
                  <div className="blog-date">
                    <ul>
                      <li>
                        <a href="/crop/03" className="h3">
                          الفراولة
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* الخوخ */}
            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="blog-card blog-card-2">
                <div className="blog-img-area">
                  <a href="/crop/10">
                    <img
                      className="allcropsImg"
                      src="/assets/images/media/crops/10/10.jpg"
                      alt="img"
                    />
                  </a>
                </div>
                <div className="blog-text-area text-center">
                  <div className="blog-date">
                    <ul>
                      <li>
                        <a href="/crop/10" className="h3">
                          الخوخ
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* البصل */}
            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="blog-card blog-card-2">
                <div className="blog-img-area">
                  <a href="/crop/08">
                    <img
                      className="allcropsImg"
                      src="/assets/images/media/crops/08/08.jpg"
                      alt="img"
                    />
                  </a>
                </div>
                <div className="blog-text-area text-center">
                  <div className="blog-date">
                    <ul>
                      <li>
                        <a href="/crop/08" className="h3">
                          البصل
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* البرقوق */}
            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="blog-card blog-card-2">
                <div className="blog-img-area">
                  <a href="/crop/12">
                    <img
                      className="allcropsImg"
                      src="/assets/images/media/crops/12/12.jpg"
                      alt="img"
                    />
                  </a>
                </div>
                <div className="blog-text-area text-center">
                  <div className="blog-date">
                    <ul>
                      <li>
                        <a href="/crop/12" className="h3">
                          البرقوق
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* الجوافة */}
            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="blog-card blog-card-2">
                <div className="blog-img-area">
                  <a href="/crop/05">
                    <img
                      className="allcropsImg"
                      src="/assets/images/media/crops/05/05.jpg"
                      alt="img"
                    />
                  </a>
                </div>
                <div className="blog-text-area text-center">
                  <div className="blog-date">
                    <ul>
                      <li>
                        <a href="/crop/05" className="h3">
                          الجوافة
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* الفلفل */}
            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="blog-card blog-card-2">
                <div className="blog-img-area">
                  <a href="/crop/06">
                    <img
                      className="allcropsImg"
                      src="/assets/images/media/crops/06/06.jpg"
                      alt="img"
                    />
                  </a>
                </div>
                <div className="blog-text-area text-center">
                  <div className="blog-date">
                    <ul>
                      <li>
                        <a href="/crop/06" className="h3">
                          الفلفل
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* المشمش */}
            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="blog-card blog-card-2">
                <div className="blog-img-area">
                  <a href="/crop/11">
                    <img
                      className="allcropsImg"
                      src="/assets/images/media/crops/11/11.jpg"
                      alt="img"
                    />
                  </a>
                </div>
                <div className="blog-text-area text-center">
                  <div className="blog-date">
                    <ul>
                      <li>
                        <a href="/crop/11" className="h3">
                          المشمش
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* الطماطم */}
            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="blog-card blog-card-2">
                <div className="blog-img-area">
                  <a href="/crop/09">
                    <img
                      className="allcropsImg"
                      src="/assets/images/media/crops/09/09.jpg"
                      alt="img"
                    />
                  </a>
                </div>
                <div className="blog-text-area text-center">
                  <div className="blog-date">
                    <ul>
                      <li>
                        <a href="/crop/09" className="h3">
                          الطماطم
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* البطاطس */}
            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="blog-card blog-card-2">
                <div className="blog-img-area">
                  <a href="/crop/14">
                    <img
                      className="allcropsImg"
                      src="/assets/images/media/crops/14/14.jpg"
                      alt="img"
                    />
                  </a>
                </div>
                <div className="blog-text-area text-center">
                  <div className="blog-date">
                    <ul>
                      <li>
                        <a href="/crop/14" className="h3">
                          البطاطس
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* التمر المصري */}
            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="blog-card blog-card-2">
                <div className="blog-img-area">
                  <a href="/crop/dates">
                    <img
                      className="allcropsImg"
                      src="/assets/images/media/crops/00/00s.jpg"
                      alt="img"
                    />
                  </a>
                </div>
                <div className="blog-text-area text-center">
                  <div className="blog-date">
                    <ul>
                      <li>
                        <a href="/crop/dates" className="h3">
                          التمر المصري
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="paginations mt-30">
            <ul>
              <li className="active">
                <a href="blog.html">1</a>
              </li>
              <li>
                <a href="blog.html">2</a>
              </li>
              <li>
                <a href="blog.html">3</a>
              </li>
              <li>
                <a href="blog.html">
                  <i className="fas fa-angle-left"></i>
                </a>
              </li>
            </ul>
          </div> */}
        </div>
      </section>
    </>
  );
}

export default All;
